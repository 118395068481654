import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import QRCode from 'qrcode.react';
import abi from './SmartPizzaABI.json';
import './ListaPizze.css';
import {Link} from 'react-router-dom';

let web3;
let contract;

function ListaPizze() {
    const [pizze, setPizze] = useState([]);
    const [pizzaDetail, setPizzaDetail] = useState(null);
    const [orderId, setOrderId] = useState('');

    useEffect(() => {
        const initWeb3 = async () => {
            const infuraApiKey = 'YOUR_INFURA_API_KEY'; // Sostituisci con la tua chiave API Infura
            const infuraEndpoint = `https://sepolia.infura.io/v3/7bd25e10128b45c9a72497011839c0a7`; // Cambia 'mainnet' se desideri una rete diversa

            web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));

            // Imposta la tua chiave privata
            const privateKey = 'c1f522bf00115ad546f14fb45dc9f3e33d61200211a78bf31984f6bfa8fc831f'; // Sostituisci con la tua chiave privata
            web3.eth.accounts.wallet.add(privateKey);

            const address = '0x347341431ef7c6B267AE7b8c1DbbB5712275f559';
            contract = new web3.eth.Contract(abi, address);
        };

        const fetchPizzas = async () => {
            try {
                await initWeb3();
                const pizzas = await contract.methods.getAllPizzas().call();
                setPizze(pizzas);
            } catch (error) {
                console.error('Error fetching pizzas:', error);
            }
        };

        fetchPizzas();
    }, []);

    const fetchPizzaDetail = async (orderId) => {
        try {
            const pizza = await contract.methods.getPizzaByOrderId(orderId).call();
            setPizzaDetail(pizza);
        } catch (error) {
            console.error('Error fetching pizza detail:', error);
        }
    };

    return (
        <div className="container">
            <div className="divStyle">
                <h1 className="h1" style={{fontFamily: 'Monaco'}}>Ricerca Pizza</h1>
                <input
                    type="text"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                    placeholder="Inserisci ID dell'ordine"
                    className="inputStyle"
                />
                <button onClick={() => fetchPizzaDetail(orderId)} className="buttonStyle">Ricerca</button>
                {pizzaDetail && (
                    <table className="tableStyle">
                        <thead>
                        <tr>
                            <th className="th" style={{textAlign: 'center'}}>Impasto</th>
                            <th className="th" colSpan="6" style={{textAlign: 'center'}}>Lievitazione</th>
                            <th className="th" style={{textAlign: 'center'}}>Condimento</th>
                            <th className="th" colSpan="2" style={{textAlign: 'center'}}>Cottura</th>
                            <th className="th" style={{textAlign: 'center'}}></th>
                        </tr>
                        <tr>
                            <th className="th" style={{textAlign: 'center'}}>Impasto</th>
                            <th className="th" style={{textAlign: 'center'}}>Ambiente</th>
                            <th className="th" style={{textAlign: 'center'}}>Umidità</th>
                            <th className="th" style={{textAlign: 'center'}}>Temp. 1° Lievitazione</th>
                            <th className="th" style={{textAlign: 'center'}}>Ambiente</th>
                            <th className="th" style={{textAlign: 'center'}}>Umidità</th>
                            <th className="th" style={{textAlign: 'center'}}>Temp. 2° Lievitazione</th>
                            <th className="th" style={{textAlign: 'center'}}>Ingredienti</th>
                            <th className="th" style={{textAlign: 'center'}}>Temp. Forno</th>
                            <th className="th" style={{textAlign: 'center'}}>Cottura (s)</th>
                            <th className="th" style={{textAlign: 'center'}}>QR Code</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="td">
                                {pizzaDetail.impasto.map((impasto, index) => (
                                    <div key={index}>{impasto}</div>
                                ))}
                            </td>
                            <td className="td">{pizzaDetail.tAmbiente1} °C</td>
                            <td className="td">{pizzaDetail.umidita1} %</td>
                            <td className="td">{pizzaDetail.lievitazione1} min</td>
                            <td className="td">{pizzaDetail.tAmbiente2} °C</td>
                            <td className="td">{pizzaDetail.umidita2} %</td>
                            <td className="td">{pizzaDetail.lievitazione2} min</td>
                            <td className="td">
                                {pizzaDetail.ingredients.map((ingredient, index) => (
                                    <div key={index}>{ingredient}</div>
                                ))}
                            </td>
                            <td className="td">{pizzaDetail.tForno} °C</td>
                            <td className="td">{pizzaDetail.time} sec</td>
                            <td className="td">
                                <Link to={`/dettaglio-pizza/${pizzaDetail.orderId}`}>
                                    <QRCode value={`${window.location.origin}/dettaglio-pizza/${pizzaDetail.orderId}`}/>
                                </Link>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                )}
            </div>
            <div className="divStyle">
                <h1 className="h1" style={{fontFamily: 'Monaco'}}>Tutte le Pizze</h1>
                <table className="tableStyle">
                    <thead>
                    <tr>
                        <th className="th" style={{textAlign: 'center'}}></th>
                        <th className="th" style={{textAlign: 'center'}}>Impasto</th>
                        <th className="th" colSpan="6" style={{textAlign: 'center'}}>Lievitazione</th>
                        <th className="th" style={{textAlign: 'center'}}>Condimento</th>
                        <th className="th" colSpan="2" style={{textAlign: 'center'}}>Cottura</th>
                        <th className="th" style={{textAlign: 'center'}}></th>
                    </tr>
                    <tr>
                        <th className="th" style={{textAlign: 'center'}}>Numero Ordine</th>
                        <th className="th" style={{textAlign: 'center'}}>Impasto</th>
                        <th className="th" style={{textAlign: 'center'}}>Temp. Ambiente</th>
                        <th className="th" style={{textAlign: 'center'}}>Umidità</th>
                        <th className="th" style={{textAlign: 'center'}}>Temp. 1° Lievitazione</th>
                        <th className="th" style={{textAlign: 'center'}}>Temp. Ambiente</th>
                        <th className="th" style={{textAlign: 'center'}}>Umidità</th>
                        <th className="th" style={{textAlign: 'center'}}>Temp. 2° Lievitazione</th>
                        <th className="th" style={{textAlign: 'center'}}>Ingredienti</th>
                        <th className="th" style={{textAlign: 'center'}}>Temp. Forno</th>
                        <th className="th" style={{textAlign: 'center'}}>Cottura (s)</th>
                        <th className="th" style={{textAlign: 'center'}}>QR Code</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pizze.map((pizza, index) => (
                        <tr key={pizza.orderId}>
                            <td className="td">{index + 1}</td>
                            <td className="td">
                                {pizza.impasto.map((impasto, index) => (
                                    <div key={index}>{impasto}</div>
                                ))}
                            </td>
                            <td className="td">{pizza.tAmbiente1} °C</td>
                            <td className="td">{pizza.umidita1} %</td>
                            <td className="td">{pizza.lievitazione1} min</td>
                            <td className="td">{pizza.tAmbiente2} °C</td>
                            <td className="td">{pizza.umidita2} %</td>
                            <td className="td">{pizza.lievitazione2} min</td>
                            <td className="td">
                                {pizza.ingredients.map((ingredient, index) => (
                                    <div key={index}>{ingredient}</div>
                                ))}
                            </td>
                            <td className="td">{pizza.tForno} °C</td>
                            <td className="td">{pizza.time} sec</td>
                            <td className="td">
                                <Link to={`/dettaglio-pizza/${pizza.orderId}`}>
                                    <QRCode value={`${window.location.origin}/dettaglio-pizza/${pizza.orderId}`}/>
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ListaPizze;
