import React, {useState, useEffect, useRef} from 'react';
import Web3 from 'web3';
import abi from './SmartPizzaABI.json';

let web3;
let contract;

function AggiungiPizza() {
    const [pizzaInfo, setPizzaInfo] = useState({ /* ... come prima ... */});
    const [metamaskDetected, setMetamaskDetected] = useState(true);
    const [selectedPhase, setSelectedPhase] = useState('IMPASTO'); // Stato iniziale
    const ownerAddress = '0x341AcEe64A1Ba02db1298C1124D793994a1F8749'; // Indirizzo del proprietario
    const [isOwner, setIsOwner] = useState(false);


    const audioRef = useRef();

    useEffect(() => {
        const initWeb3 = async () => {
            if (window.ethereum) {
                try {
                    await window.ethereum.request({method: 'eth_requestAccounts'});
                    web3 = new Web3(window.ethereum);

                    const accounts = await web3.eth.getAccounts();
                    console.log('Indirizzo dell\'utente connesso:', accounts[0]);
                    if (accounts[0].toLowerCase() === ownerAddress.toLowerCase()) {
                        // L'utente connesso è il proprietario
                        setIsOwner(true);
                    } else {
                        setIsOwner(false);
                    }
                    console.log("Test", setIsOwner)
                } catch (error) {
                    console.error('User denied account access');
                }
            } else if (window.web3 && window.web3.currentProvider) {
                web3 = new Web3(window.web3.currentProvider);
            } else {
                console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
                setMetamaskDetected(false);
                return;
            }

            const address = '0x347341431ef7c6B267AE7b8c1DbbB5712275f559';
            if (web3) {
                contract = new web3.eth.Contract(abi, address);
            }
        };

        initWeb3();
    }, []);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setPizzaInfo({
            ...pizzaInfo,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const accounts = await web3.eth.getAccounts();
            const ingredientsArray = pizzaInfo.ingredients.split(',').map(ingredient => ingredient.trim());
            const impastoArray = pizzaInfo.impasto.split(',').map(impast => impast.trim());
            const pizzaInput = {
                orderId: parseInt(pizzaInfo.orderId, 10),
                ingredients: ingredientsArray,
                impasto: impastoArray,
                tAmbiente1: parseInt(pizzaInfo.tAmbiente1, 10),
                umidita1: parseInt(pizzaInfo.umidita1, 10),
                lievitazione1: parseInt(pizzaInfo.lievitazione1, 10),
                tAmbiente2: parseInt(pizzaInfo.tAmbiente2, 10),
                umidita2: parseInt(pizzaInfo.umidita2, 10),
                lievitazione2: parseInt(pizzaInfo.lievitazione2, 10),
                tForno: parseInt(pizzaInfo.tForno, 10),
                time: parseInt(pizzaInfo.time, 10),
            };

            console.log('pizzaInput:', pizzaInput);

            const gasPrice = await web3.eth.getGasPrice();

            await contract.methods.addPizza(
                pizzaInput.orderId,
                pizzaInput.ingredients,
                pizzaInput.impasto,
                pizzaInput.tAmbiente1,
                pizzaInput.umidita1,
                pizzaInput.lievitazione1,
                pizzaInput.tAmbiente2,
                pizzaInput.umidita2,
                pizzaInput.lievitazione2,
                pizzaInput.tForno,
                pizzaInput.time
            ).send({
                from: accounts[0],
                gas: 2100000,
                gasPrice: gasPrice
            });

            console.log('Dati della pizza inviati:', pizzaInfo);

            setPizzaInfo({
                orderId: '',
                ingredients: '',
                impasto: '',
                tAmbiente1: '',
                umidita1: '',
                lievitazione1: '',
                tAmbiente2: '',
                umidita2: '',
                lievitazione2: '',
                tForno: '',
                time: '',
            });
            audioRef.current.play();
        } catch (error) {
            console.error('Errore nell’invio dei dati:', error.message);
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            {(!isOwner) && (
                <div
                    style={{
                        backgroundColor: '#ff303099',
                        color: 'white',
                        textAlign: 'center',
                        marginTop: '10px', // Riduci lo spazio superiore
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '600px',
                        margin: '10px auto',
                    }}
                >
                    ATTENZIONE: Solo il proprietario per utilizzare questa funzione!
                </div>
            )}
            <div style={{position: 'relative', display: 'flex', flexDirection: 'column'}}>
                <img
                    src="/robotpizza1.png"
                    alt="Robot Pizza"
                    style={{
                        width: '250px',
                        height: '250px',
                        position: 'absolute',
                        bottom: '15px', // Set this to align the image to the bottom
                        right: '540px',
                        zIndex: 1
                    }}
                />
                <img
                    src="/robotpizza2.png"
                    alt="Robot Pizza 2"
                    style={{
                        width: '100px',
                        height: '100px',
                        position: 'absolute',
                        top: '20px',
                        right: '80px',
                        zIndex: 1
                    }}
                />
                <div
                    style={{
                        backgroundColor: '#ff303099',
                        padding: '10px',
                        borderRadius: '10px',
                        maxWidth: '480px',
                        margin: '20px',
                        marginLeft: 'auto',
                        marginRight: '100px',
                        textAlign: 'center',
                        marginTop: '60px',
                        height: '560px', // Imposta un'altezza fissa desiderata
                        overflow: 'auto', // Aggiunge una barra di scorrimento se il contenuto supera l'altezza
                        position: 'relative', // Imposta la posizione relativa
                        width: "480px",
                    }}
                >
                    <img
                        src="/logo.png"
                        alt="SmartPizza Logo"
                        style={{
                            marginBottom: '5px',
                            maxWidth: '200px',
                        }}
                    />

                    <div>
                        <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                            <label htmlFor="orderId" className="form-label" style={{color: 'white', width: '180px'}}>Order
                                ID</label>
                            <input
                                type="number"
                                className="form-control"
                                id="orderId"
                                name="orderId"
                                value={pizzaInfo.orderId}
                                onChange={handleInputChange}
                                style={{
                                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                    border: '1px solid white',
                                    width: '80px'
                                }}
                            />
                        </div>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'IMPASTO' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('IMPASTO')}
                        >
                            IMPASTO
                            {selectedPhase === 'IMPASTO' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'LIEVITAZIONE' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('LIEVITAZIONE')}
                        >
                            LIEVITAZIONE
                            {selectedPhase === 'LIEVITAZIONE' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'CONDIMENTO' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                marginRight: '10px',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('CONDIMENTO')}
                        >
                            CONDIMENTO
                            {selectedPhase === 'CONDIMENTO' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                        <button
                            type="button"
                            className={`btn ${selectedPhase === 'COTTURA' ? 'active' : ''}`}
                            style={{
                                color: 'black',
                                backgroundColor: 'white',
                                padding: '3px 6px',
                                fontFamily: 'Monaco',
                                position: 'relative',
                                border: 'none',
                                outline: 'none',
                            }}
                            onClick={() => setSelectedPhase('COTTURA')}
                        >
                            COTTURA
                            {selectedPhase === 'COTTURA' && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        bottom: '-4px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        borderBottom: '2px solid black',
                                        width: '20px',
                                    }}
                                />
                            )}
                        </button>
                    </div>


                    <br></br>
                    {/* FASE IMPASTO */}
                    {selectedPhase === 'IMPASTO' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="impasto" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Impasto</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="impasto"
                                    name="impasto"
                                    value={pizzaInfo.impasto}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px',
                                        marginLeft: '20px',
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE LIEVITAZIONE */}
                    {selectedPhase === 'LIEVITAZIONE' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tAmbiente1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura Ambiente</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tAmbiente1"
                                    name="tAmbiente1"
                                    value={pizzaInfo.tAmbiente1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="umidita1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Umidità</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="umidita1"
                                    name="umidita1"
                                    value={pizzaInfo.umidita1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="lievitazione1" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temp. Lievitazione 1</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="lievitazione1"
                                    name="lievitazione1"
                                    value={pizzaInfo.lievitazione1}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px',
                                        marginRight: '10px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tAmbiente2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temperatura Ambiente</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tAmbiente2"
                                    name="tAmbiente2"
                                    value={pizzaInfo.tAmbiente2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="umidita2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Umidità</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="umidita2"
                                    name="umidita2"
                                    value={pizzaInfo.umidita2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="lievitazione2" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Temp. Lievitazione 2</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="lievitazione2"
                                    name="lievitazione2"
                                    value={pizzaInfo.lievitazione2}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px',
                                        marginRight: '10px'
                                    }}
                                />
                            </div>
                        </div>
                    )}


                    {/* FASE CONDIMENTO */}
                    {selectedPhase === 'CONDIMENTO' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="ingredients" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Ingredienti</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="ingredients"
                                    name="ingredients"
                                    value={pizzaInfo.ingredients}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '200px',
                                        marginLeft: '20px',
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    {/* FASE COTTURA */}
                    {selectedPhase === 'COTTURA' && (
                        <div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="tForno" className="form-label" style={{color: 'white', width: '180px'}}>Temperatura
                                    Forno</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="tForno"
                                    name="tForno"
                                    value={pizzaInfo.tForno}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                            <div className="mb-2" style={{display: 'flex', alignItems: 'center'}}>
                                <label htmlFor="time" className="form-label"
                                       style={{color: 'white', width: '180px'}}>Cottura</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="time"
                                    name="time"
                                    value={pizzaInfo.time}
                                    onChange={handleInputChange}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                        border: '1px solid white',
                                        width: '80px'
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    <hr
                        style={{
                            borderColor: 'white',
                            borderStyle: 'dashed',
                            borderWidth: '2px',
                            position: 'absolute', // Imposta la posizione assoluta
                            bottom: '55px', // Posiziona l'elemento 30px sopra il bottone "Aggiungi Pizza"
                            left: '50%', // Centra l'elemento orizzontalmente
                            transform: 'translateX(-50%)', // Centra l'elemento orizzontalmente
                            width: '100%', // Imposta la larghezza al 100% per coprire tutto il contenitore
                        }}
                    />

                    <button type="submit" className="btn btn-light" style={{
                        color: 'black',
                        marginTop: '10px',
                        position: 'absolute', // Imposta la posizione assoluta
                        bottom: '20px', // Posiziona il bottone in fondo al div
                        left: '50%', // Centra il bottone orizzontalmente
                        transform: 'translateX(-50%)', // Centra il bottone orizzontalmente
                    }}>
                        Aggiungi Pizza
                    </button>
                </div>
                <audio ref={audioRef} src="/sound.mp3" preload="auto"></audio>
            </div>
        </form>
    );
}

export default AggiungiPizza;
