import React, {useState, useEffect} from 'react';
import Web3 from 'web3';
import abi from './SmartPizzaABI.json';
import './DettaglioPizza.css';
import {useParams} from 'react-router-dom'; // Import useParams

let web3;
let contract;

function DettaglioPizza() {
    const {orderId} = useParams(); // Extract orderId from URL parameters
    const [pizzaDetail, setPizzaDetail] = useState(null);

    useEffect(() => {
        const initWeb3 = async () => {
            const infuraApiKey = 'YOUR_INFURA_API_KEY'; // Sostituisci con la tua chiave API Infura
            const infuraEndpoint = `https://sepolia.infura.io/v3/7bd25e10128b45c9a72497011839c0a7`; // Cambia 'mainnet' se desideri una rete diversa

            web3 = new Web3(new Web3.providers.HttpProvider(infuraEndpoint));

            // Imposta la tua chiave privata
            const privateKey = 'c1f522bf00115ad546f14fb45dc9f3e33d61200211a78bf31984f6bfa8fc831f'; // Sostituisci con la tua chiave privata
            web3.eth.accounts.wallet.add(privateKey);

            const address = '0x347341431ef7c6B267AE7b8c1DbbB5712275f559';
            contract = new web3.eth.Contract(abi, address);
        };

        const fetchPizzaDetail = async () => {
            try {
                await initWeb3();
                if (orderId) {
                    const pizza = await contract.methods.getPizzaByOrderId(orderId).call();
                    setPizzaDetail(pizza);
                }
            } catch (error) {
                console.error('Error fetching pizza detail:', error);
            }
        };

        fetchPizzaDetail();
    }, [orderId]); // Add orderId as a dependency for useEffect

    return (
        <div className="container">
            <div className="divStyle">
                <h1>Dettagli Pizza</h1>
                {pizzaDetail ? (
                    <table className="tableStyle">
                        <thead>
                        <tr>
                            <th className="th" style={{textAlign: 'center'}}>Impasto</th>
                            <th className="th" colSpan="6" style={{textAlign: 'center'}}>Lievitazione</th>
                            <th className="th" style={{textAlign: 'center'}}>Condimento</th>
                            <th className="th" colSpan="2" style={{textAlign: 'center'}}>Cottura</th>
                        </tr>
                        <tr>
                            <th className="th" style={{textAlign: 'center'}}>Impasto</th>
                            <th className="th" style={{textAlign: 'center'}}>Temp. Ambiente</th>
                            <th className="th" style={{textAlign: 'center'}}>Umidità</th>
                            <th className="th" style={{textAlign: 'center'}}>Temp. 1° Lievitazione</th>
                            <th className="th" style={{textAlign: 'center'}}>Temp Ambiente</th>
                            <th className="th" style={{textAlign: 'center'}}>Umidità</th>
                            <th className="th" style={{textAlign: 'center'}}>Temp. 2° Lievitazione</th>
                            <th className="th" style={{textAlign: 'center'}}>Ingredienti</th>
                            <th className="th" style={{textAlign: 'center'}}>Temp. Forno</th>
                            <th className="th" style={{textAlign: 'center'}}>Cottura (s)</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="td">
                                {pizzaDetail.impasto.map((impasto, index) => (
                                    <div key={index}>{impasto}</div>
                                ))}
                            </td>
                            <td className="td">{pizzaDetail.tAmbiente1} °C</td>
                            <td className="td">{pizzaDetail.umidita1} %</td>
                            <td className="td">{pizzaDetail.lievitazione1} min</td>
                            <td className="td">{pizzaDetail.tAmbiente2} °C</td>
                            <td className="td">{pizzaDetail.umidita2} %</td>
                            <td className="td">{pizzaDetail.lievitazione2} min</td>
                            <td className="td">
                                {pizzaDetail.ingredients.map((ingredient, index) => (
                                    <div key={index}>{ingredient}</div>
                                ))}
                            </td>
                            <td className="td">{pizzaDetail.tForno} °C</td>
                            <td className="td">{pizzaDetail.time} sec</td>
                        </tr>
                        </tbody>
                    </table>
                ) : (
                    <p>Caricamento in corso...</p>
                )}
            </div>
            {/* Aggiungi il div per le spiegazioni sugli ingredienti */}
            <div className="ingredientsExplanation">
                <h2>Origine degli Ingredienti</h2>
                <p>
                    Il nostro ristorante si impegna a utilizzare ingredienti di alta qualità provenienti
                    da fonti affidabili. Ecco un breve riassunto dell'origine degli ingredienti principali:
                </p>
                <ul>
                    <li><strong>Olio:</strong> Il nostro olio extra vergine di oliva proviene dalle migliori
                        olive coltivate nelle colline dell'Italia.
                    </li>
                    <li><strong>Farina:</strong> Utilizziamo farina biologica, macinata da grano coltivato in
                        campi locali, per garantire la massima freschezza.
                    </li>
                    <li><strong>Mozzarella:</strong> La mozzarella fresca viene preparata giornalmente utilizzando
                        il latte fresco proveniente da mucche locali.
                    </li>
                    <li><strong>Pomodoro:</strong> Il nostro pomodoro maturato al sole viene raccolto direttamente
                        dalle nostre coltivazioni di pomodori in Italia.
                    </li>
                </ul>
                <p>
                    Siamo orgogliosi di offrire ai nostri clienti una pizza di alta qualità con ingredienti
                    selezionati con cura e provenienti da fonti affidabili.
                </p>
            </div>
        </div>
    );
}

export default DettaglioPizza;
